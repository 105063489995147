import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { sentry } from '@features';
import {
  areClustersPresentSelector,
  configSelector,
  userSelector,
} from '@selectors';
import { useSelector } from '@hooks';

import { usePlatformContext } from './usePlatformContext';

export const useAuthentication = () => {
  const { username, email, termsAccepted } = useSelector(userSelector);
  const { orgs: organizations = [] } = useSelector(configSelector);
  const areClustersPresent = useSelector(areClustersPresentSelector);

  const { initializePlatformContext } = usePlatformContext();
  const { isAuthenticated, error, user } = useAuth0();

  /**
   * To run platform workflows a user is needed being added to cluster
   */
  const isClusterRequired = isAuthenticated && !areClustersPresent;

  /**
   * Onboarding manages organization creation for new users only,
   * because creating a project requires adding it to cluster
   */
  const isOnboardingRequired =
    isAuthenticated && isClusterRequired && organizations.length === 0;

  /**
   * Auth0 login/logout starts from page reload via redirecting
   */
  useEffect(() => {
    if (isAuthenticated) {
      initializePlatformContext();
    }
  }, [isAuthenticated, initializePlatformContext]);

  useEffect(() => {
    try {
      if (username) {
        sentry.setUser({ username, email });
      }
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  }, [email, username]);

  return {
    isAuthenticated,
    isOnboardingRequired,
    isClusterRequired,
    termsAccepted,
    authError: error,
    user,
  };
};
