import { isNumber, isString } from './typeof';

type Value = string | number;

export class AppCommand {
  private command: string = '';

  private args: string[] = [];

  private settings: { [key: string]: Value } = {};

  public construct(command: string): AppCommand {
    this.command = command;

    return this;
  }

  public arg(value: string): AppCommand {
    if (value) {
      this.args.push(value);
    }

    return this;
  }

  public set(key: string, value: Value | null | undefined): AppCommand {
    /**
     * Skip invalid or unexpected value to adding as part of settings
     */
    const isValueValid = (isString(value) && !!value) || isNumber(value);

    if (isValueValid) {
      this.settings[key] = value;
    }

    return this;
  }

  private parseSettings(): string {
    return Object.entries(this.settings).reduce(
      (settings, [key, value]) => `${settings} --set "${key}=${value}"`,
      '',
    );
  }

  private parseArgs(): string {
    return this.args.reduce(
      (args, value, index) =>
        `${args} --set "serverExtraArgs[${index}]=${value}"`,
      '',
    );
  }

  public setHost(clusterName: string, appName: string) {
    this.set(
      'ingress.hosts[0].host',
      `${appName}.apps.${clusterName}.org.neu.ro`,
    );

    return this;
  }

  public compose(): string {
    return `${this.command} ${this.parseSettings()} ${this.parseArgs()}`;
  }
}
