import { useEffect, useState } from 'react';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';

import { ModalProps, Secret } from '@typings';
import { getSecrets } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useFetchList, useSelector } from '@hooks';
import { as, cn, noop } from '@utils';

import { Button, ChaseSpinner, Field, Icon, Modal } from '@components';
import { EmptyContent } from '@components/Ui';

type Props = ModalProps & {
  onAdd: (field: { name: string; value: string; type: 'secret' }) => void;
};

export const JobConstructorSecretModal = ({
  onAdd,
  closeModal = noop,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const {
    isFetched,
    isEmpty,
    list: secrets,
    getList,
  } = useFetchList<Secret[]>({
    getList: getSecrets,
    fetchOnMount: false,
  });

  const [name, setName] = useState('');
  const [activeSecret, setActiveSecret] = useState('');

  const { secretsUrl } = as.c(cluster);

  useEffect(() => {
    const getSecretList = async () => {
      if (projectName) {
        await getList({ secretsUrl, organizationName, projectName });
      }
    };

    getSecretList();
  }, [organizationName, projectName, secretsUrl, getList]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSecretAdd = () => {
    onAdd({ name, value: activeSecret, type: 'secret' });
    closeModal();
  };

  const makeSecret = ({ key }: Secret) => {
    const isActive = key === activeSecret;

    return (
      <Button
        theme
        className="group flex w-full items-center gap-3 border-b border-neural-01 p-2"
        onClick={() => setActiveSecret(key)}
      >
        <Icon
          icon={faLockKeyhole}
          className={cn(
            'h-10 w-10 rounded-full bg-stroke text-black transition-colors',
            { '!bg-primary !text-white': isActive },
          )}
        />
        <div className="text-left">
          <p>{key}</p>
          <p className="text-caption text-neural-03">**********</p>
        </div>
      </Button>
    );
  };

  const renderSecretsContent = () => {
    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent
          icon={faLockKeyhole}
          className="mt-4 min-h-[400px]"
          title="No secrets found"
        />
      );
    }

    return (
      <div className="flex flex-col gap-2">
        <Field.Input
          required
          containerClassName="mb-4"
          className="w-full"
          label="Name"
          value={name}
          onChange={handleNameChange}
        />
        {secrets.map(makeSecret)}
        <Modal.Footer className="px-0">
          <Button
            className="px-10 capitalize"
            disabled={!activeSecret || !name}
            onClick={handleSecretAdd}
          >
            Add secret
          </Button>
        </Modal.Footer>
      </div>
    );
  };

  return (
    <Modal.Content title="Add Environment Secret" className="w-[640px]">
      {renderSecretsContent()}
    </Modal.Content>
  );
};
