import { AnchorHTMLAttributes, MouseEventHandler } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { cn, VariantProps } from '@utils';
import { buttonVariants } from '@utils/variants';

type BaseLinkProps = {
  theme?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type RouterLinkProps = {
  to: To;
  external?: false;
};

type AnchorLinkProps = {
  external: true;
  blank?: boolean;
  to: string;
};

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof buttonVariants> &
  BaseLinkProps &
  (RouterLinkProps | AnchorLinkProps);

export const Link = ({
  to,
  children,
  theme,
  disabled = false,
  className,
  variant,
  external = false,
  ...props
}: Props) => {
  const classNames = theme
    ? className
    : cn(buttonVariants({ variant, className }), {
        'pointer-events-none': disabled,
      });

  if (external) {
    /**
     * todo: review types
     */
    const { blank, ...anchorProps } =
      props as AnchorHTMLAttributes<HTMLAnchorElement> &
        Pick<AnchorLinkProps, 'blank'>;
    const target = blank ? '_blank' : '';
    const linkOptions = {
      ...anchorProps,
      className: classNames,
      target,
      href: to,
      disabled,
    } as AnchorHTMLAttributes<HTMLAnchorElement>;

    return (
      // eslint-disable-next-line react/no-invalid-html-attribute
      <a {...linkOptions} rel="noreferrer nooperer">
        {children}
      </a>
    );
  }

  const options = {
    ...props,
    className: classNames,
    to,
    disabled,
  };

  return <RouterLink {...options}>{children}</RouterLink>;
};
