import { useState } from 'react';
import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';

import { Cluster, ModalProps } from '@typings';
import { cn, getFormattedMemory, getGpuFromResourcePreset, noop } from '@utils';

import { Button, Icon, Modal, Table } from '@components';

type Props = ModalProps & {
  resourcePresets: Cluster.ResourcePreset[];
  onApply?: (
    presetName: string,
    resourcePreset: Cluster.ResourcePreset,
  ) => void;
};

export const JobConstructorPresetsModal = ({
  resourcePresets,
  onApply = noop,
  closeModal = noop,
}: Props) => {
  const [activeResourcePreset, setActiveResourcePreset] =
    useState<Cluster.ResourcePreset | null>(null);

  const handlePresetApply = () => {
    /**
     * Apply button is disabled while no active resource preset,
     * that is why non-null assertion operator is used
     */
    const resourcePreset = activeResourcePreset!;

    onApply(resourcePreset.name, resourcePreset!);
    closeModal();
  };

  const getBooleanValue = (value: boolean | undefined | null) =>
    value ? '+' : '-';

  const makeResourcePreset = (resourcePreset: Cluster.ResourcePreset) => {
    const {
      name,
      cpu,
      schedulerEnabled,
      creditsPerHour,
      memory,
      isPreemptible,
    } = resourcePreset;
    const { gpu, gpuModel } = getGpuFromResourcePreset(resourcePreset);
    const isActive = name === activeResourcePreset?.name;
    const className = cn(
      'cursor-pointer border-neural-01 py-2 transition-colors group-hover:bg-neural-01',
      { '!bg-neural-02': isActive },
    );

    return (
      <Table.Row
        key={name}
        onClick={() => setActiveResourcePreset(resourcePreset)}
        className="group"
      >
        <Table.Cell className={className}>
          <div className="flex items-center gap-2">
            <Icon icon={faMicrochip} />
            <span className="truncate">{name}</span>
          </div>
        </Table.Cell>
        <Table.Cell className={className}>{cpu}</Table.Cell>
        <Table.Cell className={className}>
          {getFormattedMemory(memory)}
        </Table.Cell>
        <Table.Cell className={className}>
          {gpu ? `${gpu} x ${gpuModel}` : '-'}
        </Table.Cell>
        <Table.Cell className={className}>
          {getBooleanValue(schedulerEnabled)}
        </Table.Cell>
        <Table.Cell className={className}>
          {getBooleanValue(isPreemptible)}
        </Table.Cell>
        <Table.Cell className={className}>{creditsPerHour}</Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Modal.Content title="Choose Preset" className="w-[1024px]">
      <Table className="mt-4 w-full auto-rows-min grid-cols-[auto_repeat(2,min-content)_repeat(2,max-content)_repeat(2,min-content)] overflow-auto bg-background">
        <Table.Header>
          <Table.Row>
            <Table.Head className="bg-background">Name</Table.Head>
            <Table.Head className="bg-background">CPU</Table.Head>
            <Table.Head className="bg-background">Memory</Table.Head>
            <Table.Head className="bg-background">GPU</Table.Head>
            <Table.Head className="bg-background">Round-robin</Table.Head>
            <Table.Head className="bg-background">Preemptible</Table.Head>
            <Table.Head className="bg-background">Price/Hour</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{resourcePresets.map(makeResourcePreset)}</Table.Body>
      </Table>
      <Modal.Footer sticky>
        <Button
          className="px-10"
          disabled={!activeResourcePreset}
          onClick={handlePresetApply}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
